.App {
  text-align: center;
}

html,
body {
  font-family: "Montserrat", "Cairo", sans-serif !important;
  font-weight: 500 !important ;
  padding: 5px;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.ant-select-selector {
  height: 52px !important;
  padding-top: 7px !important;
}

.ant-select-selection-search-input {
  margin-top: 5px !important;
}

.PhoneInputInput {
  padding-top: 13px;
  padding-bottom: 13px;
  border: 1px solid #dadada;
  border-radius: 1px;
  outline-color: #40a9ff7a !important;
}

.PhoneInputCountry {
  padding-top: 13px;
  padding-bottom: 13px;
  padding-right: 8px;
  padding-left: 8px;
  border: 1px solid #dadada;
  border-radius: 2px;
}

.ant-steps-finish-icon {
  position: relative;
  top: -3px;
}

.divider {
  height: 1px;
  background-color: #474444;
  width: 100%;
}

.goHover {
  color: #474444;
  -webkit-transition: background 0.2s; /* For Safari 3.0 to 6.0 */
  transition: background 0.2s; /* For modern browsers */
}
.goHover:hover {
  color: white;
  background-color: #3ed16f !important;
}

.basicHover {
  color: #474444;
  -webkit-transition: background 0.2s; /* For Safari 3.0 to 6.0 */
  transition: background 0.2s; /* For modern browsers */
}
.basicHover:hover {
  background-color: #3ec4d1 !important;
  color: white;
}

.advancedHover {
  color: #474444;
  -webkit-transition: background 0.2s; /* For Safari 3.0 to 6.0 */
  transition: background 0.2s; /* For modern browsers */
  border: 2px solid #0099cc;
}
.advancedHover:hover {
  background-color: #0099cc !important;
  color: white;
}

.enterpriseHover {
  color: #474444;
  -webkit-transition: background 0.2s; /* For Safari 3.0 to 6.0 */
  transition: background 0.2s; /* For modern browsers */
}
.enterpriseHover:hover {
  background-color: #0d42fb !important;
  color: white;
}

.goHover:hover .divider {
  background-color: white !important;
}

.basicHover:hover .divider {
  background-color: white !important;
}

.advancedHover:hover .divider {
  background-color: white !important;
}

.enterpriseHover:hover .divider {
  background-color: white !important;
}

.chooseButtonBorder {
  border: 1px solid #474444;
}

.goHover:hover .chooseButtonBorder {
  border: 1px solid transparent !important;
}

.basicHover:hover .chooseButtonBorder {
  border: 1px solid transparent !important;
}

.advancedHover:hover .chooseButtonBorder {
  border: 1px solid transparent !important;
}

.enterpriseHover:hover .chooseButtonBorder {
  border: 1px solid transparent !important;
}

.packageDetailModal .ant-modal-header {
  display: none !important;
}

.packageDetailModal .ant-modal-footer {
  display: none !important;
}

.ant-modal-content {
  border-radius: 10px !important;
}

@media only screen and (max-width: 768px) {
  .ant-modal-body {
    background: none !important;
  }
}

/* @media only screen and (max-width: 500px) {

} */
@media only screen and (max-width: 574px) {
  .progressStepper {
    max-width: 125px !important;
    padding-top: 40px !important;
  }
  .ordable-header {
    position: absolute !important;
    font-size: 14px !important;
  }
  .simplified {
    display: none !important;
  }
}
@media only screen and (max-width: 1050px) {
  .custom-radio {
    float: unset !important;
    margin: 20px 0px !important;
    display: block !important;
  }
  .radio-group {
    margin-top: 120px;
  }
  .usernameName {
    display: none !important;
  }
}

@media only screen and (max-width: 643px) {
  .packageDetailsList {
    display: none !important;
  }
  .findOutMore {
    bottom: 15px !important;
  }
  .divider {
    display: none !important;
  }
}

.ant-radio-button-wrapper {
  background: #c4c4c4 !important;
  color: white !important;
}

.ant-radio-button-checked {
  background: white !important;
  color: #474444 !important;
  transform: scale(0.95, 0.9) !important;
  border-radius: 20px !important;
}

.ant-radio-button-checked + span {
  color: #474444 !important;
  font-weight: 500 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  border-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover::before {
  background-color: transparent !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active::before {
  background-color: transparent !important;
}

html {
  --antd-wave-shadow-color: transparent !important;
}

.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background: #0099cc !important;
}

.ant-steps-item-process .ant-steps-item-icon {
  border-color: #0099cc !important;
}

.bold {
  font-weight: bold;
}
.underline {
  text-decoration: underline;
}
.center {
  text-align: center;
}
